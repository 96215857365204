import { InitClinet } from './socket'
const rtmSocket = {
  wsclient: null,
  wsLogin,
  localSortMessage,
  sendMesgChannel,
  leavechannel,
  wsChannelJoin,
  wsLoginSuccess,
  wsLogout,
  wsInterrupted,
  wsRemoteLogin,
  collectionLocalSortMessage
  // channel.sendMessage
}

async function wsLogin(params) {
  const self = this
  this.wsclient = await InitClinet({
    parent: this,
    uid: params.uid,
    channelid: params.channelid || '321',
    ChannelJoin: (message) => {
      console.log('成功加入房间', message)
      setTimeout(() => {
        self.wsChannelJoin({
          key: 'success-join',
          value: message
        })
      });
    },
    MessageFromPeer: (message, peerId) => {
      console.log(message, peerId, '收到消息MessageFromPeer')
    },
    ChannelMessage: (message, peerId) => {
      console.log('ChannelMessage', message);
      if (message.text !== 'UELaunched' && message.text !== 'UELevelInitFinish') {
        self.localSortMessage(JSON.parse(message.text))
        const newMessage = JSON.parse(message.text)
      } else {
        self.localSortMessage(message.text)
        
      }
      
    },
    LoginSuccess: () => {
      console.log('登录成功')
      self.wsLoginSuccess({
        key: 'login-in',
        value: ''
      })
    },
    Logout: () => {
      console.log('退出登录')
      // self.wsLogout({
      //   key: 'login-out',
      //   value: ''
      // })
      // self.leavechannel()
    },
    Interrupted: () => {
      console.log('连接中断')
      // self.leaveOut(true)
      self.wsInterrupted({
        key: 'link-break',
        value: ''
      })
      if (self.channel != null) {
        self.channel.leave()
        self.channel = null
        self.wsclient = null
      }
    },
    RemoteLogin: async () => {
      console.log('被人挤出了')
      self.wsRemoteLogin({
        key: 'login-remote',
        value: ''
      })
      // self.leaveOut(false)
      self.channel = null
    }
  })
}
function sendMesgChannel(val, flag) {
  const vals = JSON.stringify(val)
  if (this.channel != null) {
    if(vals.ControlType === "MRActorTransform" &&
      vals.TargetID === 'Main_0'&&
      vals.DeltaX === "0" &&
      vals.DeltaY === "0" &&
      vals.DeltaZ === "0" 
      ){
        return
      }else{
        this.channel.sendMessage({ text: vals }).then(() => {
          console.log('自己发送的频道消息: ', this.channel.channelId, ' Message ', JSON.parse(vals))
        })
     }
  }
}
async function leavechannel() {
  if (this.channel != null) {
    await this.channel.leave()
    this.channel = null
  }
}

function localSortMessage(val, id) {
  console.log('getMesg', val, id)
}
function collectionLocalSortMessage(val, id) {
  console.log('asdasdasdasdasd', val, id)
  return val
}

function wsChannelJoin() { }
function wsLoginSuccess() { }
function wsLogout() { }
function wsInterrupted() { }
function wsRemoteLogin() { }

export default rtmSocket
