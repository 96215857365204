import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import rtmSocket from './socket/message'
import { randomString } from './utils/index'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$rtmSocket = rtmSocket
Vue.prototype.$randomString = randomString
// 生成随机id
function genID(length) {
  return Number(
    Math.random().toString().substr(3, length) + Date.now()
  ).toString(36)
}
// 随机生成id
Vue.prototype.$genID = genID
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
