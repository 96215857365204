
let appID = '60abcfe97def44e48014531e79295e04'
if (document.domain === 'aigc.mrstage.com') {
  appID = '60abcfe97def44e48014531e79295e04'
} else {
  appID = 'ec090252134b449eae30b433c2820f7a'
}
// import AgoraRTM from 'agora-rtm-sdk'
// window.AgoraRTM = AgoraRTM
// import store from '@/store'
//

// 正常用户登录 ------------------------------------------
export const InitClinet = function async(params) {
  if (!(this instanceof InitClinet)) {
    return new InitClinet(params)
  }
  if (!params.uid) return console.log('InitClinet:uid ', params.uid)
  this.options.token = null
  this.options.uid = params.uid
  this.client = window.AgoraRTM.createInstance(appID, {
    logFilter: window.AgoraRTM.LOG_FILTER_ERROR
  })
  // params.parent?.channel = null
  this.addListener(params)
  return new Promise(async (resolve) => {
    await this.client.login(this.options)
    resolve(this.client)
  })
}
const init_clinet_proto = InitClinet.prototype

init_clinet_proto.options = {
  uid: '',
  token: ''
}

init_clinet_proto.addListener = function (params) {
  this.client.on('MessageFromPeer', function (message, peerId) {
    params.MessageFromPeer && params.MessageFromPeer(message, peerId)
  })

  this.client.on('ConnectionStateChanged', async (state, reason) => {
    console.log('状态更改为: ' + state + ' 原因: ' + reason)
    switch (reason) {
      case 'LOGIN_SUCCESS':
        params.LoginSuccess && params.LoginSuccess()
        this.channelEvent(params)
        break
      case 'LOGOUT':
        params.Logout && params.Logout()
        break
      case 'INTERRUPTED':
        params.Interrupted && params.Interrupted()
        break
      case 'REMOTE_LOGIN':
        params.RemoteLogin && params.RemoteLogin()
        break
      default:
        break
    }
  })
}

init_clinet_proto.channelEvent = async function (params) {
  // const channelNum = params.uid.split("_")[0];
  const channelNum = params.channelid
  const channel = this.client.createChannel(channelNum)
  await channel.join().then(() => {
    params.ChannelJoin && params.ChannelJoin(channel)
  })
  channel.on('ChannelMessage', function (message, memberId) {
    params.ChannelMessage && params.ChannelMessage(message, memberId)
  })
  // 显示加入频道
  channel.on('MemberJoined', function (memberId) {
    console.log(memberId + ' 加入频道')
  })
  // 频道成员离开
  channel.on('MemberLeft', function (memberId) {
    console.log(memberId + ' 离开频道')
  })
  if (params.parent) {
    params.parent.channel = channel
  }
}
