import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Demo',
    component: () => import('../views/Demo.vue')//实时数字人+离线数字人，展厅使用
  },
  {
    path: '/demo2',
    name: 'Demo2',
    component: () => import('../views/Demo2.vue')//新华智云数字人demo
  },
  {
    path: '/demo3',
    name: 'Demo3',
    component: () => import('../views/Demo3.vue')//商汤数字人demo
  },
  {
    path: '/demo4',
    name: 'Demo4',
    component: () => import('../views/Demo4.vue')//新华智云数字人说话demo
  },
  {
    path: '/demo5',
    name: 'Demo5',
    component: () => import('../views/Demo5.vue')//商汤数字人说话demo
  },
  {
    path: '/demo6',
    name: 'Demo6',
    component: () => import('../views/Demo6.vue')//深锶数字人demo
  },
  {
    path: '/demo7',
    name: 'Demo7',
    component: () => import('../views/Demo7.vue')//深锶数字人demo提供给单机版ue使用
  },
  {
    path: '/demo8',
    name: 'Demo8',
    component: () => import('../views/Demo8.vue')//七牛云数字人说话demo
  },
  {
    path: '/demo9',
    name: 'Demo9',
    component: () => import('../views/Demo9.vue')//腾讯语音转文字demo
  },
  {
    path: '/demo10',
    name: 'Demo10',
    component: () => import('../views/Demo10.vue')//慧言科技demo
  },
  {
    path: '/demo11',
    name: 'Demo11',
    component: () => import('../views/Demo11.vue')//七牛云文本mp3直播接管demo
  },
  {
    path: '/demo12',
    name: 'Demo12',
    component: () => import('../views/Demo12.vue')//文字转图片demo
  },
  {
    path: '/demo13',
    name: 'Demo13',
    component: () => import('../views/Demo13.vue')//给p2pro用的 七牛云
  },
  {
    path: '/demo14',
    name: 'Demo14',
    component: () => import('../views/Demo14.vue')//自研数字人
  },
  {
    path: '/demo15',
    name: 'Demo15',
    component: () => import('../views/Demo15.vue')//自研数字人
  },
  {
    path: '/demo16',
    name: 'Demo16',
    component: () => import('../views/Demo16.vue')//自研数字人
  },
  {
    path: '/demo17',
    name: 'Demo17',
    component: () => import('../views/Demo17.vue')//自研数字人
  },
  {
    path: '/web3d',
    name: 'web3d',
    component: () => import('../views/web3d.vue')//3d场景渲染
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
